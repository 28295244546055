<template>
  <div class="header_about">
    <div class="container-fluid px-0">
      <div class="main_header">
      <h2>{{ header.title }}</h2>
      <p>{{  header.text}}</p>
    </div>
    <div class="img">
      <img :src="header.image" alt="about" />
    </div>
    </div>
   
  </div>
</template>

<script>
import { aboutData } from "@/api/about/header.js";

export default {
  data() {
    return {
      header: {},
    };
  },
  mounted() {
    aboutData().then((response) => {
      this.header = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
