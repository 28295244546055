<template>
  <div class="text">
    <h2>{{ $t("add_comment") }}</h2>
    <p>{{ $t("rate_comment") }}</p>
  </div>
  <form @submit.prevent="submitForm()">
    <rate :length="5" v-model="form.myRate"  />
   
    <!-- start input group -->
    <div class="input-group">
      <label class="form-label"> {{ $t("maesaage") }}</label>
      <input
        type="text"
        class="form-control"
        :placeholder="$t('enter_full_name')"
        
        v-model="form.name"
        required
        
      />
      <span class="icon_form">
        <i class="bi bi-user"></i>
      </span>
    </div>
    <!-- finish input group -->
    <!-- start input group -->
    <div class="input-group">
      <label class="form-label"> {{ $t("maesaage") }}</label>
      <textarea
        class="form-control"
        :placeholder="$t('write_comment')"
        v-model="form.notes"
      ></textarea>
    </div>
    <!-- finish input group -->

    <!-- start input group -->
    <div class="input-group">
      <div class="button">
        <button class="btn">{{ $t("send") }}</button>
      </div>
    </div>
    <!-- finish input group -->
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      // rate: 0,
      form: {
        notes: "",
        name: "",
        value: "",
        myRate:''
      },
      guideId: localStorage.getItem('id')
    };
  },
  methods: {
    logRating(rating) {
      console.log("User selected a rating of", rating.value);
    },
     submitForm() {
      let data = {
        lawyer_id: localStorage.getItem("id"),
        user_name: this.form.name,
        comment: this.form.notes,
        rate: this.form.myRate,
      };
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/add_rate", data).then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: data.message,
            timer: 1500,
          });
          
        } else if(data.status == false){
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: data.message,
            timer: 1500,
          });
        }
      });
    },

    fetch_blog_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.data = data.data;
        // console.log(this.cards);
        // Get the id value from the data object
        const id = this.data.id;
        // Set the id value in local storage
        localStorage.setItem("id", id);
      });
    },
  },
  created() {
    this.fetch_blog_data();
  },
};
</script>

<style></style>
