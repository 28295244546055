<template>
  <div class="categories">
    <div class="heade_blog">
      <h4>{{ $t("cats") }}</h4>
    </div>
    <div class="cat_cards">
      <div class="cat_card" v-for="(card, index) in category" :key="index">
        <router-link :to="`/category/${card.id}`">
          <h6>{{ card.title }}</h6>
        <span>({{ card.count }})</span>
        </router-link>
        
      </div>
    </div>
  </div>
</template>

<script>
import { blogsData } from "@/api/blogs/categories.js";

export default {
  data() {
    return {
      category: [], 
      
    };
  },
  mounted() {
    blogsData().then((response) => {
      this.category = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
