<template>
  <div class="sidebar">
    <div class="img">
      <img :src="cards.image" alt="sidebar" />
    </div>
    <div class="text">
      <h4>{{ cards.name }}</h4>
      <p v-html="cards.description " ></p>
    </div>

    <div class="info">
      <i class="bi bi-geo-alt"></i>

      <p v-html="cards.address" class="parg"></p>
    </div>
    <!-- <div class="info">
      <i class="bi bi-credit-card"></i>
      <p class="parg">{{ $t("cost_const") }} : {{ cards.cost }}</p>
    </div> -->
    <div class="button">
        <router-link class="btn first" to="/legality/order"
      >{{ $t("show_all_lawyer") }} <i class="bi bi-arrow-left"></i>
    </router-link>
    </div>
  
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "sidebar",
  data() {
    return {
      cards: {
         data:[]
        },
    };
  },
  methods: {
    fetch_data_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.cards = data.data;
        // console.log(this.cards);
      });
    },
  },
  created() {
    this.fetch_data_data();
  },
};
</script>

<style></style>
