<template>
  <div class="card">
    <div class="img">
      <img :src="legal.image" alt="cards" />
    </div>
    <div class="information">
      <h4>{{ legal.name }}</h4>
      <p v-html="legal.description.substring(0, 100) + ' ...'"></p>

     
      <div class="info">
        <i class="bi bi-geo-alt"></i>

        <p v-if="legal.address" class="parg">{{ legal.address }} </p>
      </div>
      <!-- <div class="info">
        <i class="bi bi-credit-card"></i>
        <p class="parg">{{ $t('cost_const') }} : {{ legal.cost}}</p>
      
      </div> -->
    
    </div>
  </div>
</template>

<script>
export default {
name:'card',
props:['legal'],
}
</script>

<style>

</style>