<template>

  <section class="cat_page">
    <div class="container">
    <div class="card_blogs">
      <div class="row">
        <div
          class="col-lg-4 col-md-6 col-12"
          v-for="(card, index) in cards.data"
          :key="index"
        >
          <router-link :to="`/blogs/${card.id}`">
            <div class="card">
              <img :src="card.image" alt="blog" />
              <span>{{ card.time }}</span>
              <h4>{{ card.title }}</h4>
              <p v-html="card.text" ></p>
              <router-link :to="`/blogs/${card.id}`" class="btn second">{{
                $t("read_more")
              }}</router-link>  
            </div>
          </router-link>
        </div>
      </div>
      <div class="row">
        <pagination
          :data="cards"
          class="mx-auto d-flex align-items-center justify-content-center pagination"
          @pagination-change-page="fetch_blog_data"
        >
          <span slot="prev-nav">&lt;</span>
          <span slot="next-nav">&gt;</span>
        </pagination>
      </div>
    </div>
  </div>
  </section>
 
</template>

<script>
// import { blogsData } from "@/api/blogs/cards.js";
import axios from "axios";
import pagination from "laravel-vue-pagination";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      cards: {},
    };
  },
  methods: {
    fetch_blog_data(page = 1) {
      let id = { law_category_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("law_category_blogs?page=" + page, id).then(({ data }) => {
        this.cards = data.data;
        // console.log(this.cards);
      });
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  created() {
    this.fetch_blog_data();
  },
};
</script>

<style scoped>
  .cat_page {
    margin-top: 8rem;
  }
</style>
