<template>
  <div class="steps_section">
    <div class="container">
       <div class="main_header">
        <h2>{{$t('steps_title')  }}</h2>
        <p>{{ text.simple_steps_text }}</p>
       </div>
       <div class="row steps">
        <div class="col-lg-6 col-md-6 col-12 step" v-for="(step , index) in data" :key="index">
            <span> {{ index + 1 }}</span>
            <h4>{{ step.title }}</h4>
            <p v-html="step.text"></p>
        </div>
       </div>
    </div>
  </div>
</template>

<script>
import { advData } from "@/api/adv/steps.js";
import { textData } from "@/api/text/text.js";


export default {
  data() {
    return {
      data: [],
      text:{},
    };
  },
  mounted() {
    advData().then((response) => {
      this.data = response.data.data;
      // console.log(this.header);
      return response;
    });
    textData().then((response) => {
      this.text = response.data.data;
      // console.log(this.header);
      return response;
    });
  },

}
</script>

<style>

</style>