<template>
  <section class="login_page">
    <div class="container">
      <ul class="tabs_login">
        <li>
          <router-link :to="{ name: 'Join' }">
            <h3>{{ $t("join") }}</h3>
          </router-link>
        </li>
        <li class="active">
          <router-link :to="{ name: 'login' }">
            <h3>{{ $t("login") }}</h3>
          </router-link>
        </li>
      </ul>
      <div class="text">
        <h2>{{ $t("login") }}</h2>
        <p>{{ $t("plz_cont") }}</p>
      </div>
      <form @submit.prevent="login">
        <div class="input-group">
          <label class="form-label"> {{ $t("email") }}</label>
          <input
            type="email"
            class="form-control"
            :placeholder="$t('full_email')"
            v-model="form.email"
            required
          />
          <span class="icon_form">
            <i class="bi bi-envelope"></i>
          </span>
        </div>

        <div class="input-group">
          <label class="form-label"> {{ $t("password") }}</label>
          <input
            type="password"
            class="form-control"
            :placeholder="$t('password')"
            v-model="form.password"
            required
          />
          <span class="icon_form">
            <i class="bi bi-lock"></i>
          </span>
        </div>
        <router-link class="forget" :to="{ name: 'forgetPassword' }">{{
          $t("forget_pass")
        }}</router-link>
        <button type="submit" class="btn first">{{ $t("login") }}</button>
      </form>
    </div>
  </section>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      let response = await this.$store.dispatch("Login", this.form);
      // console.log(response);
      if (this.form.email === "" || this.form.password === "") {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: this.$t("Please dictate all fields"),
          timer: 1500,
        });
      } else if (response.data.status == true) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: response.data.message,
          timer: 1500,
        });
        this.form = {
          email: "",
          password: "",
        };
        this.$router.go("/dashboard");
        this.error = null;
      }
    },
  },
};
</script>

<style></style>
