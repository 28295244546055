<template>
  <div class="contracts_page">
    <div class="container">
        <div class="main_header">
            <h2>{{ $t('make_contract') }}</h2>
            <p></p>
        </div> 
        <div class="img">
            <img src="../assets/image/contacts.png" alt="contract">
        </div>
        <div v-html="text.text" class="content">

        </div>
    </div>
  </div>
</template>

<script>
import { contData } from "@/api/contracts/text.js";

export default {
    data() {
    return {
      text: {},
    };
  },
   mounted() {
    contData().then((response) => {
      this.text = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
}
</script>

<style>

</style>