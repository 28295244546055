<template>
  <div class="lastnews_blog">
    <div class="heade_blog">
      <h4>{{ $t("last_news") }}</h4>
    </div>
    <div class="lastnews_cards">
      <div v-for="(card, index) in lastNews.data" :key="index">
        <router-link class="lastnews_card" :to="`/blogs/${card.id}`">
          <div class="img">
            <img :src="card.image" alt="blog" />
          </div>
          <div class="info">
            <h6>{{ card.title }}</h6>
            <span>{{ card.date }}</span>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { blogsData } from "@/api/blogs/lastNews.js";

export default {
  data() {
    return {
      lastNews: [],
    };
  },
  mounted() {
    blogsData().then((response) => {
      this.lastNews = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
