<template>
   <lawyerCards
            :cards="cards" 
          />
          
</template>
<script>
import lawyerCards from "@/components/lawyer/lawyerCardDetials.vue";
import axios from "axios";

export default {
    components: {
    lawyerCards,
  },
  data() {
    return {
        cards: {
         data:[]
        },
    };
  },
  methods: {
    fetch_blog_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.cards = data.data;
        // console.log(this.cards);
      });
    },
  },
  created() {
    this.fetch_blog_data();
  },
}
</script>

<style>

</style>