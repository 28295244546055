<template>
  <div
    class="container-fluid"
    style="background-color: #e5e5e5; padding: 10% 0"
  >
    <div class="row justify-content-center">
      <div class="col-lg-6 col-md-8 col-12">
        <div class="accept_offer">
          <div class="img">
            <img src="../../assets/image/accept.png" alt="accept" />
          </div>
          <h2>{{ $t("accept_consultant") }}</h2>
          <p>{{ $t("accept_tetx") }}</p>
          <div class="button">
            <button
              type="button"
              class="btn first"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
             
            >
              {{ $t("add_link") }}
            </button>
            <router-link class="btn second" :to="{ name: 'homeDashboard' }">
              {{ $t("add_later") }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <div
    class="modal fade accept_modal"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ $t("add_link_consult") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p>{{ $t("plz_copy_link") }}</p>
          <form @submit.prevent=" addLink()">
            <input
              type="url"
              class="form-control"
              :placeholder="$t('copy_link')"
              v-model="form.link"
            />
            <button type="submit" class="btn first">
            {{ $t("add_link") }}
          </button>
          </form>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      form: {
        request_id: this.$route.params.id,
        link:''
      }
    };
  },
  methods: {
    async  addLink() {
      try{
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("token")}`;
        const { data } = await axios.post("/add_request_link", this.form);
        this.status = data.status;
        this.message = data.message;
        if (this.status == true) {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: this.message,
            timer: 1500,
          });
            this.$router.go("/dashboard");
        } else {
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: this.message,
            timer: 1500,
          });
        }
      
      }
      catch (error) {
        // console.log(error);
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: error.response.data.message,
          timer: 1500,
        });
      }
    
    },
    
  },
};
</script>

<style></style>
