<template>
  <section class="service_section">
    <div class="container">
      <service-header />
      <div class="row services_box">
        <div
          class="col-lg-4 col-md-6 col-12 service_box"
          v-for="(services, index) in service"
          :key="index"
        >
          <img :src="services.image" alt="service" />
          <h4>{{ services.title }}</h4>
          <p v-html="services.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import serviceHeader from "@/components/home/serviceHeader.vue";
import { serviceData } from "@/api/home/serviceSection.js";

export default {
  components: {
    serviceHeader,
  },
  data() {
    return {
      service: [],
    };
  },
  mounted() {
    serviceData().then((response) => {
      this.service = response.data.data;
      //    console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
