<template>
  <div class="publish_section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="text">
            <h2>{{ data.title }}</h2>
            <p>{{ data.text }}</p>
            <router-link :to="{ name: 'Contract' }" class="first btn">{{
              $t("annouc_now")
            }}</router-link>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="img">
            <img :src="data.image" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { publishData } from "@/api/adv/publish.js";

export default {
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    publishData().then((response) => {
      this.data = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
