<template>
  <div class="header_lawyer">
    <div class="text">
      <h2>{{ header.title }}</h2>
      <p v-html="header.text" ></p>
    </div>
    <router-link class="btn second" to="/guide">{{ $t('know_more') }} </router-link>
  </div>

</template>

<script>
import {lawyerData} from "@/api/lawyer/header.js"

export default {
  data() {
    return {
      header: {}
    };
  },
  mounted() {
    lawyerData().then((response) => {
      this.header = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
}
</script>

<style>

</style>