<template>
   <div v-if="cards.new_requests.length == []"  class="row">
       <h2 class="text-center my-5 py-5" >{{ $t('no_new_card') }}</h2>
  </div>
  <div v-else class="row">
    <div
      class="col-lg-6 col-12"
      v-for="(card, index) in cards.new_requests"
      :key="index"
    >
      <div  class="consult_card">
        <div class="header_card">
          <div class="date">
            <span> {{ $t("consult_time") }}</span>
            <h6>{{ formatDate(card.date) }}</h6>
          </div>
          <div class="action">
            <button @click="acceptRequest(card.id)" class="btn correct">
              <i class="bi bi-check2"></i>
            </button>
            <button @click="deleteRequest(card.id)" class="btn wrong">
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
        </div>
        <div class="content">
          <span>
            <i v-if="card.contact_type == 1" class="bi bi-camera-video"></i>
            <i v-if="card.contact_type == 2" class="bi bi-telephone"></i>
          </span>
          <div class="text">
            <h4 v-if="card.contact_type == 1">{{ $t("vedio_consultant") }}</h4>
            <h4 v-if="card.contact_type == 2">{{ $t("call_consultant") }}</h4>
            <p>{{ card.user_name }} , {{ card.lawyer_email }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  data() {
    return {
      cards: {
        new_requests: [],
      },
    };
  },
  methods: {
    fetch_card_data() {
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      axios.get("/your_requests").then(({ data }) => {
        this.cards = data.data;
        // console.log(this.cards);
      });
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
    deleteRequest(request_id) {
      let id = { request_id: request_id };
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${localStorage.getItem("token")}`;
      axios.post("/cancel_request", id).then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: data.message,
            timer: 1500,
          });
        } else if (data.status == false) {
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: data.message,
            timer: 1500,
          });
        }
        this.cards = data.data;
        // console.log(this.cards);
      });
    },

    // accept request
    async acceptRequest(request_id) {
      try {
        let id = { request_id: request_id };
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("token")}`;
        const { data } = await axios.post("/accept_request", id);
        this.status = data.status;
        this.message = data.message;

        if (this.status == true) {
        
          this.$router.push(`/accept-consult/${request_id}`);
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: this.message,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: this.message,
            timer: 1500,
          });
        }
      } catch (error) {
        // console.log(error);
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: error.response.data.message,
          timer: 1500,
        });
      }
    },
  },
  created() {
    this.fetch_card_data();
  },
};
</script>

<style></style>
