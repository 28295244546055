<template>
  <section class="single_blog">
    <div class="container">
      <span class="data text-center">{{ formatDate(cards.time) }}</span>
      <div class="main_header">
        <h2>{{ cards.title }}</h2>
        <!-- <p>{{ $t("single_text") }}</p> -->
      </div>

      <img class="w-100" :src="cards.image" alt="blogs" />
      <div v-html="cards.text"></div>
      
      <div class="social_div" >
        <h4>{{ $t('share') }}</h4>
        <ul class="social_icon">
        
        <li>
          <ShareNetwork
            network="facebook"
            :url="$websiteUrl+'blog/'+cards.id"
            :title="cards.title"
          >
            <i class="bi bi-facebook"></i>
          </ShareNetwork>
        </li>
        <li>
          <ShareNetwork
            network="Twitter"
            :url="$websiteUrl+'blog/'+cards.id"
            :title="cards.title"
          >
          <i class="bi bi-twitter"></i>
          </ShareNetwork>
        </li>
        <li>
          <ShareNetwork
            network="WhatsApp"
            :url="$websiteUrl+'blog/'+cards.id"
            :title="cards.title"
          >
          <i class="bi bi-whatsapp"></i>
          </ShareNetwork>
        </li>
        <li>
          <ShareNetwork
            network="LinkedIn"
            :url="$websiteUrl+'blog/'+cards.id"
          >
          <i class="bi bi-linkedin"></i>
          </ShareNetwork>
        </li>
      </ul>
      </div>
  
    </div>
  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      cards: {},
    };
  },

  methods: {
    fetch_blog_data() {
      let id = { blog_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/blog_details", id).then(({ data }) => {
        this.cards = data.data;
        // console.log(this.cards);
      });
    },
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY");
    },
  },
  created() {
    this.fetch_blog_data();
  },
};
</script>

<style></style>
