<template>
  <section class="service_page">
    <Header />
    <lawyer-service />
    <citizen-service />
  </section>
</template>

<script>
import Header from "@/components/service/header.vue";
import citizenService from "@/components/service/citizenService.vue";
import lawyerService from "@/components/service/lawyerService.vue";


export default {
components:{
    Header ,
    citizenService,
    lawyerService
}
}
</script>

<style>

</style>