<template>
  <div class="card">
    <div class="img">
      <img :src="cases.image" alt="cards" />
    </div>
    <div class="information">
      <h4>{{ cases.name }}</h4>
      <p v-html="cases.description.substring(0, 100) + ' ...'" ></p>

     
      <div class="info">
        <i class="bi bi-geo-alt"></i>

        <p v-html="cases.address" class="parg"> </p>
      
      </div>
      <!-- <div class="info">
        <i class="bi bi-credit-card"></i>
        <p class="parg">{{ $t('cost_const') }} : {{ cases.cost}}</p>
      
      </div> -->
    
    </div>
  </div>
</template>

<script>
export default {
name:'card',
props:['cases'],
}
</script>

<style>

</style>
