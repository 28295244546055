<template>
  <div class="reservation">
    <div class="text">
      <h2>{{ $t("personalData") }}</h2>
      <p>{{ $t("انشئ حساب لك الآن و أدخل البيانات التالية للاستمرار") }}</p>
    </div>

    <form @submit.prevent="submitForm()">
      <div class="input-group">
        <label class="form-label">
          {{ $t("full_name") }}
        </label>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('enter_full_name')"
          v-model="join.name"
          @input="validateName"
          required
        />
        <span class="icon_form">
          <i class="bi bi-user"></i>
        </span>
        <!-- <span v-if="errors.name">{{ errors.name }}</span> -->
      </div>

      <div class="input-group">
        <label class="form-label">
          {{ $t("phone") }}
        </label>
        <input
          type="tel"
          class="form-control"
          :placeholder="$t('phone_placeholder')"
          v-model="join.phone"
          @input="validatePhoneNumber"
          required
        />
        <span class="icon_form">
          <i class="bi bi-phone"></i>
        </span>
        <!-- <span v-if="errors.email">{{ errors.email }}</span> -->
      </div>
      <div class="input-group">
        <label class="form-label">
          <input type="checkbox" v-model="join.checked" />
          {{ $t("agree_with") }}
          <router-link target="_blank" :to="{ name: 'Condition' }">{{
            $t("condition")
          }}</router-link>
          {{ $t("special") }}
        </label>
      </div>
      <div class="input-group">
        <div class="button">
          <button
            :disabled="!join.checked"
            :class="{ 'btn-enabled': join.checked }"
            class="btn first"
            type="submit"
          >
            {{ $t("save_confirm") }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      join: {
        phone: "",
        name: "",
        checked: false,
      },
    };
  },
  methods: {
    async submitForm() {
       // Validate phone number
       if (!this.validatePhoneNumber(this.join.phone)) {
        Swal.fire({
          icon: "error",
          title: this.$t("Invalid phone number") ,
          text: this.$t("Please enter a valid phone number") ,
        });
        return;
      }
    
      const objectFrom = JSON.parse(localStorage.getItem("legalkey"));
      const objectFromFirstPage = JSON.parse(localStorage.getItem("legalkey1"));
      let data = {
        // lawyer_id: localStorage.getItem("id"),
        user_name: this.join.name,
        user_phone: this.join.phone,
        contact_type: objectFrom.contact_type,
        date: objectFrom.date,
        lawyer_day_time_id: objectFrom.lawyer_day_time,
        lawyer_id: objectFromFirstPage.cardId,
        law_service_id: objectFromFirstPage.serviceValue,
      };
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/store_consultant_request", data).then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: data.message,
            timer: 1500,
          });
          localStorage.removeItem("legalkey");
          localStorage.removeItem("legalkey1");
        } else if (data.status == false) {
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: data.message,
            timer: 1500,
          });
        }
      });
    },
    validatePhoneNumber(phone) {
      // Regular expression to match phone number format
      const regex = /^\d{10}$/;
      return regex.test(phone);
    },
  },
};
</script>

<style></style>
