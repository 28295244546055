<template>
  <div class="container">
  <div class="row about_lawyer_citizen">
    <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="img justify-content-start ">
        <img :src="lawerService.image" alt="service" />
      </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12">
      <div class="text">
        <h2>{{ lawerService.title }}</h2>
        <p v-html="lawerService.text" ></p>
        <router-link class="first btn" to="/service">{{ $t('know_more') }}</router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { lawyerData } from "@/api/about/lawyer.js";

export default {
  data() {
    return {
      lawerService: {},
    };
  },
  mounted() {
    lawyerData().then((response) => {
      this.lawerService = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
