<template>
  <div class="card_blogs">
    <div class="row">
      <div class="col-lg-6 col-12" v-for="(card , index) in cards" :key="index" >
        <router-link :to="`/blogs/${card.id}`">
        <div class="card"  >
          <img :src="card.image" alt="blog">
          <span>{{  card.date }}</span>
          <h4>{{ card.title }}</h4>
          <p v-html="card.text"></p>
          <router-link :to="`/blogs/${card.id}`"  class="btn second" >{{ $t('read_more') }}</router-link>
        </div>
        </router-link>
      </div>
    </div>
  </div>
  
</template>

<script>
import { blogsData } from "@/api/blogs/cards.js";

export default {
    data() {
    return {
      
    };
  },
  mounted() {
    blogsData().then((response) => {
      this.cards = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
}
</script>

<style>

</style>