<template>
  <div class="founder_about">
    <div class="container">
    <div class="main_header">
      <h2>{{ $t("founder") }}</h2>
      <!-- <p>{{ founder.text }}</p> -->
    </div>
    <div class="founder_cards">
      <div
        class="founder_card"
        v-for="(card, index) in founder"
        :key="index"
      >
        <img :src="card.image" alt="dounder" />
        <h4>{{ card.name }}</h4>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import { aboutData } from "@/api/about/founder.js";

export default {
  data() {
    return {
      founder: {
        data:[]
      },
    };
  },
  mounted() {
    aboutData().then((response) => {
      this.founder = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
