<template>
    <Single />
</template>

<script>
import Single from "@/components/blogs/single.vue"

export default {
components:{
    Single
}
}
</script>

<style>

</style>