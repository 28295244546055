<template>
  <div class="gaols_about">
    <div class="container">
      <div class="main_header">
        <h2>{{ goals.title }}</h2>
        <p>{{ goals.text }}</p>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-12 col-12">
          <div class="img">
            <img :src="goals.image" alt="goals" />
          </div>
        </div>
        <div class="col-lg-8 col-md-12 col-12">
          <div class="lists">
            <div class="list">
              <img src="../../assets/image/List.png" alt="list" />
              <h6>{{ $t("vision_1") }}</h6>
              <p>{{ goals.our_vision }}</p>
            </div>

            <div class="list">
              <img src="../../assets/image/List.png" alt="list" />
              <h6>{{ $t("vision_2") }}</h6>
              <p>{{ goals.our_value }}</p>
            </div>

            <div class="list">
              <img src="../../assets/image/List.png" alt="list" />
              <h6>{{ $t("vision_3") }}</h6>
              <p>{{ goals.our_goal }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { aboutData } from "@/api/about/goals.js";

export default {
  data() {
    return {
      goals: {},
    };
  },
  mounted() {
    aboutData().then((response) => {
      this.goals = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
