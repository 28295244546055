<template>
  <Navbar />
  <router-view></router-view>
  <Help />
  <Watss />
  <Footer />
</template>

<script>
import Navbar from "@/components/layout/navbar.vue";
import Help from "@/components/layout/help.vue";
import Watss from "@/components/layout/watss.vue";
import Footer from "@/components/layout/footer.vue";
require("./assets/scss/style.min.css");
require("./assets/scss/style_ltr.min.css");
require("./assets/css/hover.min.css");
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
    Help,
    Watss,
  },
};
</script>

<style></style>
