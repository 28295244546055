<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleVideo"
    tabindex="-1"
    aria-labelledby="exampleVideoLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <video :src="video" type="video/mp4" width="420" height="240" controls muted>
            
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: ["video"],
  method: {
    close() {},
  },
};
</script>

<style></style>
