<template>
  <nav class="detials_nav" id="navbar-example">
      <ul class="nav-links navbar-nav">
      <li>
        <a class="nav-link active" aria-current="page" href="#scrollspyHeading1"
          >{{ $t("about") }}
        </a>
      </li>
      <li>
        <a class="nav-link" aria-current="page" href="#scrollspyHeading2"
          >{{ $t("office_service") }}
        </a>
      </li>
      <li>
        <a class="nav-link" aria-current="page" href="#scrollspyHeading3">{{
          $t("img_liberary")
        }}</a>
      </li>

      <li>
        <a class="nav-link" aria-current="page" href="#scrollspyHeading4">{{
          $t("customer_app")
        }}</a>
      </li>
      <li>
        <a class="nav-link" aria-current="page" href="#scrollspyHeading5">{{
          $t("contact_office")
        }}</a>
      </li>

      <li>
        <a class="nav-link" aria-current="page" href="#scrollspyHeading6">{{
        
        }}</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {};
</script>

<style></style>
