<template>
  <form @submit.prevent="contact" class="contact">
    <div class="input-group">
      <label class="form-label"> {{ $t("full_name") }}</label>
      <input
        type="text"
        class="form-control"
        :placeholder="$t('enter_full_name')"
        v-model="change.name"
        required
      />
      <span class="icon_form">
        <i class="bi bi-person"></i>
      </span>
    </div>

    <div class="input-group">
      <label class="form-label"> {{ $t("email") }}</label>
      <input
        type="email"
        class="form-control"
        :placeholder="$t('full_email')"
        v-model="change.email"
        required
      />
      <span class="icon_form">
        <i class="bi bi-envelope"></i>
      </span>
    </div>

    <div class="input-group">
      <label class="form-label"> {{ $t("maesaage") }}</label>
      <textarea
        class="form-control"
        :placeholder="$t('whrie_message')"
        v-model="change.message"
      ></textarea>
    </div>
    <div class="input-group">
      <div class="button">
        <button type="submit" class="btn">{{ $t("send") }}</button>
      </div>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      change: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    async contact() {
      const formData = new FormData();
      formData.append("name", this.change.name);
      formData.append("email", this.change.email);
      formData.append("message", this.change.message);
      axios
        .post("/store_website_message", formData)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
          });
        });
    },
  },
};
</script>

<style></style>
