import axios from "axios";
export default {
 
 
  
  async Login({ commit }, form) {
    try {
      
      let response = await axios.post("https://lawyers-guide.crazyideaco.com/website/lawyer_login", form);
      localStorage.setItem("token", response.data.data.api_token);
      localStorage.setItem("user", response.data.data.name);
      commit("setUser", { user: response });

      return response;
    } catch (error) {
      return error.response;
    }
  },

 

  logout({ commit }) {
    commit("logout");
  },
};
