<template>
  <section class="contract_page">
    <div class="container">
        <div class="text">
            <h2>{{ $t('adv_us') }}</h2>
            <p>{{ $t('adv_text') }}</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-6 col-12">
            <Form />
            </div>
            <div class="col-lg-6 col-md-6 col-12">
                <div class="img">
                    <img src="../assets/image/contract_2.png" alt="contract">
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import Form from "@/components/contract/form.vue";

export default {
components:{
    Form
}
}
</script>

<style>

</style>