<template>
    <section class="legality_detials">
      <div class="breadcrumb_section">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-6 col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">
                    {{ $t("home_page") }}</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("addvice_order") }}
                </li>
              </ol>
            </nav>

            <!-- <div class="text">
              <h2>{{ details.title }}</h2>
              <p>{{ $t("lawyer_desc") }}</p>
            </div> -->
          </div>
          <div class="col-lg-5 col-md-6 col-12">
            <div class="img">
              <img src="../../assets/image/breadcremp.png" alt="breadcrumb" />
            </div>
          </div>
        </div>
      </div>
    </div>
 
        <div class="container">
            <div class="content"></div>
    <div class="row">
        <div class="col-lg-3 col-md-4 col-12">
         <Sidebar />
        </div>
        <div class="col-lg-9 col-md-8 col-12">
          <PersonalData />
        </div>
    </div>
  </div>
    </section>
 
</template>


<script>
import breadcrumbLawyer from "@/components/layout/breadcrumbLawyer.vue";
import PersonalData from "@/components/legalitySection/personalData.vue";
import Sidebar from "@/components/legalitySection/sidebar.vue";

export default {
  components: {
    breadcrumbLawyer,
    Sidebar,
    PersonalData
},
  data() {
    return {
    
    };
  },
};
</script>

<style></style>
