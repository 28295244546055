<template>
  <section class="application_section">
    <div class="container ">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="text">
            <h2>{{ application.title }}</h2>

            <p v-html="application.text"></p>
            <div class="links">
              <a href="{{ application.apple_app_link  }}" target="__blank">
                <img src="../../assets/image/app.png" alt="appel" />
              </a>
              <a href="{{ application.android_app_link }}" target="__blank">
                <img src="../../assets/image/google.png" alt="google" />
              </a>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <div class="img">
            <img :src="application.image" alt="app" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { applicationData } from "@/api/home/application.js";

export default {
  data() {
    return {
      application: {},
    };
  },
  mounted() {
    applicationData().then((response) => {
      this.application = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
