<template>
  <div class="tags">
    <div class="heade_blog">
      <h4>{{ $t("tag") }}</h4>
    </div>
    <div class="tag_cards">
      <div class="tag_card" v-for="(card, index) in tag" :key="index">
        <router-link :to="`/tags/${card.id}`">
          {{ card.title }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { blogsData } from "@/api/blogs/tags.js";

export default {
  data() {
    return {
      tag: [],
    };
  },
  mounted() {
    blogsData().then((response) => {
       
      this.tag = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
