<template>
    <div class="lawyer_header_second">
        <div class="main_header">
        <h2>{{ header.title }}</h2>
        <p v-html="header.text" ></p>
      </div>
      <div class="img">
        <img src="../../assets/image/asds.jpg" alt="">
      </div>
   
    </div>
    
  
  </template>
  
  <script>
  import {lawyerData} from "@/api/lawyer/header.js"
  
  export default {
    data() {
      return {
        header: {}
      };
    },
    mounted() {
      lawyerData().then((response) => {
        this.header = response.data.data;
        // console.log(this.header);
        return response;
      });
    },
  }
  </script>
  
  <style>
  
  </style>