<template>
  <div class="header_blog">
    <div class="container-fluid px-0">
      <div class="img">
        <img :src="header.image" alt="blog" />
      </div>
      <div class="text">
    <h2>{{ header.title }}</h2>
    <p v-html="header.text" ></p>
      </div>
    </div>
  </div>
</template>

<script>
import { blogsData } from "@/api/blogs/header.js";
export default {
  data() {
    return {
      header: {
        data:[]
      },
    };
  },
  mounted() {
    blogsData().then((response) => {
      this.header = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>


