<template>
  <div class="contact_office" id="scrollspyHeading5">
    <h2>{{ $t("contact_office") }}</h2>
    <p>{{ $t("contact_text") }}</p>
    <form @submit.prevent="submitForm">
      <!-- start input group -->
      <div class="input-group">
        <label class="form-label">
          {{ $t("full_name") }}
        </label>
        <input
          type="text"
          class="form-control"
          :placeholder="$t('enter_full_name')"
          v-model="name"
          required
        />
        <span class="icon_form">
          <i class="bi bi-user"></i>
        </span>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="input-group">
        <label class="form-label">
          {{ $t("phones") }}
        </label>
        <input
          type="tel"
          class="form-control"
          :placeholder="$t('phone_placeholder')"
          v-model="phone"
          required
        />
        <span class="icon_form">
          <i class="bi bi-telephone"></i>
        </span>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="input-group">
        <label class="form-label">
          {{ $t("email") }}
        </label>
        <input
          type="email"
          class="form-control"
          :placeholder="$t('full_email')"
          v-model="email"
          required
        />
        <span class="icon_form">
          <i class="bi bi-envelope"></i>
        </span>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="input-group">
        <label class="form-label"> {{ $t("maesaage") }}</label>
        <textarea
          class="form-control"
          :placeholder="$t('write_here')"
          v-model="notes"
        ></textarea>
      </div>
      <!-- finish input group -->

      <!-- start input group -->
      <div class="input-group">
        <div class="button">
          <button class="btn">{{ $t("send") }}</button>
        </div>
      </div>
      <!-- finish input group -->
    </form>

    <div class="can_contact">
      <p>{{ $t("can_contact") }}</p>
      <ul>
        <li>
          <a target="__blank" :href="data.facebook">
            <img src="../../../assets/image/face.png" alt="social" />
          </a>
        </li>

        <li>
          <a target="__blank" :href="data.twitter">
            <img src="../../../assets/image/twiter.png" alt="social" />
          </a>
        </li>

        <li>
          <a target="__blank" :href="data.youtube">
            <img src="../../../assets/image/youtube.png" alt="social" />
          </a>
        </li>

        <li>
          <a target="__blank" :href="data.tiktok">
            <img src="../../../assets/image/tiktik.png" alt="social" />
          </a>
        </li>

        <li>
          <a target="__blank" :href="data.linkedin">
            <img src="../../../assets/image/linkedin.png" alt="social" />
          </a>
        </li>

        <li>
          <a target="__blank" :href="data.instagram">
            <img src="../../../assets/image/instgram.png" alt="social" />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "contact",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      notes: "",
      data: {},
    };
  },
  methods:{
    async submitForm() {
      let data = {
        lawyer_id: localStorage.getItem("id"),
        name: this.name,
        email: this.email,
        phone: this.phone,
        message: this.notes,
      };

      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/store_contact_with_lawyer", data).then(({ data }) => {
        if (data.status == true) {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: data.message,
            timer: 1500,
          });
        } else if(data.status == false){
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: data.message,
            timer: 1500,
          });
        }
      });
    },


    fetch_blog_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.data = data.data;
        // console.log(this.cards);
      });
    },
  },
  created() {
    this.fetch_blog_data();
  },

};
</script>

<style></style>
