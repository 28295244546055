<template>
  <section class="about_page">
      <Header />
      
      <img class="shape" src="../assets/image/shape-about.png" alt="shape">
      <Goals />
      <div class="container">
        <div class="text text_about">
        <h2>{{ $t('about_title') }}</h2>
        <p>{{ $t('about_text') }}</p>
      </div>
      </div>
     
      <lawyer />
      <citizen />
      <application />
      <founder />
      <pranter />

  </section>
</template>

<script>
import Header from "@/components/about/header.vue";
import Goals from "@/components/about/goals.vue";
import lawyer from "@/components/about/lawyer.vue";
import citizen from "@/components/about/citizen.vue";
import application from "@/components/home/application.vue";
import founder from "@/components/about/founder.vue";
import pranter from "@/components/home/pranter.vue";

export default {
  components: {
    Header,
    Goals,
    lawyer,
    citizen ,
    application,
    founder,
    pranter
  },
};
</script>

<style></style>
