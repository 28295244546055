<template>
<section class="adv_page">
    <Header />
    <Publish />
    <Steps />
    <ready />
</section>
</template>

<script>
import Header from "@/components/adv/header.vue";
import Publish from "@/components/adv/publish.vue";
import Steps from "@/components/adv/steps.vue";
import ready from "@/components/adv/ready.vue";



export default {
components:{
    Header ,
    Publish,
    Steps,
    ready
}
}
</script>

<style>

</style>