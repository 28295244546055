<template>
  <section class="lawyer_detials">
    <div class="container">
      <div class="lawyer_section">
        <Card />
      </div>
      
      <Content />
    </div>
  </section>
</template>

<script>
import Content from "@/components/lawyerDetials/content.vue";
import Card from "@/components/lawyerDetials/cardLawyer.vue";
export default {
  components: {
    Card,
    Content,
  },
};
</script> 

<style></style>
