<template>
  <div class="main_header">
    <h2>{{ $t('about_title') }}</h2>
    <p v-html="header.lawyer_service_text"></p>
  </div>
</template>

<script>
import {textData} from "@/api/text/text.js"

export default {
  data() {
    return {
        header:{} 
    };
  },
  mounted() {
    textData().then((response) => {
      this.header = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
