<template>
  <section class="lawyer_section">
    <div class="container">
      <lawyer-header />

      <div class="row">
        <div class="col-lg-3 col-md-4 col-12">
          <lawyer-filter />
        </div>
        <div class="col-lg-9 col-md-8 col-12">
          <lawyerCards
            v-for="(card, index) in data"
            :key="index"
            :cardSS="card" 
          />
          <img
            class="card-sd"
            src="../../assets/image/card-asd.jpg"
            alt="card"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import lawyerHeader from "@/components/lawyer/lawyerHeader.vue";
import lawyerFilter from "@/components/lawyer/lawyerFilter.vue";
 import lawyerCards from "@/components/lawyer/lawyerCards.vue";
import { lawyerData } from "@/api/home/lawyerCard.js";


export default {
  components: {
     lawyerCards,
    lawyerHeader,
    lawyerFilter,
  },
  data() {
    return {
      data: {},
    };
  },
  mounted() {
    lawyerData().then((response) => {
      // console.log(response);
      this.data = response.data.data;
      //  console.log(this.data);
      return response;
    });
  },
};
</script>

<style></style>
