<template>
  <div class="experts" >
    <h2>{{ $t("lawyer_experts") }}</h2>
    <ul>
      <li v-for="(lists, index) in data.lawyer_experiences" :key="index">
        {{ lists.text }}
      </li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      data: {
      },
    };
  },
  methods: {
    fetch_blog_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.data = data.data;
        // console.log(this.cards);
      });
    },
  },
  created() {
    this.fetch_blog_data();
  },
};
</script>

<style></style>
