<template>
  <div class="lawyer_cards lawyer_cards_grid" >
  <router-link :to="`/guide/${card.id}`" >
    <div class="img">
      <img :src="card.image" alt="cards" />
    </div>
  </router-link>
    <div class="information">
      <div class="head">
        <h4>{{ card.name }}</h4>
        <!-- <div    class="drop_menu">
          <span @click="show = !show" ><i class="bi bi-three-dots-vertical"></i></span>
          <dropMenu v-show="show" />
        </div> -->
      </div>
      <p v-html="card.description.substring(0, 100) + ' ...'" ></p>
      <div class="info">
        <i class="bi bi-telephone"></i>
        <a class="parg" href="tel:{{ card.phone }}"> {{ card.phone }} </a>
      </div>
      <div class="info">
        <i class="bi bi-geo-alt"></i>

        <p v.show="card.address " class="parg">{{ card.address }}</p>
        <span v-show="card.map_link" >
          <a :href="card.map_link" target="__blank">
            <i class="bi bi-map-fill"></i
          ></a>
        </span>
      </div>
      <div class="info infos">
        <a class="watts" :href="card.whatsapp" target="__blank"
          ><i class="bi bi-whatsapp"></i
        ></a>
        <a class="second btn" :href="'mailto:' + card.email" >
          <i class="bi bi-envelope"></i> 
        </a>
        <a v-show="card.website" class="second btn" :href= "card.website" target="__blank"
          ><i class="bi bi-globe-europe-africa"></i>
        </a>
      </div>
    </div>
  
</div>
</template>

<script>
import dropMenu from "@/components/lawyer/dropMenu.vue";

export default {
    components:{
        dropMenu
    },
    data(){
    return{
      show:false,
    }
  },
  props: ["card"],
};
</script>

<style></style>
