<template>
  <section class="login_page">
    <div class="container">
      <div class="text">
        <h2>{{ $t("reset_password") }}</h2>
        <p>{{ $t("enter_reset_password") }}</p>
      </div>
      <form @submit.prevent="resetPassword()">
        <div class="input-group">
          <label class="form-label"> {{ $t("password") }}</label>
          <input
            type="password"
            class="form-control"
            v-model="change.password"
            required
          />
          <span class="icon_form">
            <i class="bi bi-lock"></i>
          </span>
        </div>
        <button type="submit" class="btn first">{{ $t("send") }}</button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "login",
  data() {
    return {
      change: {
        password: "",
      },
    };
  },
  methods: {
    methods: {
      async resetPassword() {
        const formData = new FormData();
        formData.append("password", this.change.password);
        axios
          .post("/reset_password", formData)
          .then((response) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "success",
            });
            this.$router.push("/login");
          })
          .catch((error) => {
            Swal.fire({
              title: "",
              text: response.data.message,
              icon: "error",
            });
          });
      },
    },
  },
};
</script>

<style></style>
