<template>
  <div class="information_links">
     <div class="text">
      <h2 class="text-white">{{ $t('keep_totch') }}</h2>
      <p class="text-white p-0">{{ $t('keep_totch_text') }}</p>
     </div>
     <ul class="list">
      <li>
        <span><i class="bi bi-envelope"></i></span>
        <div class="info">
           <h6>{{ $t('email') }}</h6> 
           <p>{{ information.email }}</p>
        </div>
      </li>

      <li>
        <span><i class="bi bi-telephone"></i></span>
        <div class="info">
           <h6>{{ $t('phone') }}</h6> 
           <p >{{ information.phone }}</p>
        </div>
      </li>

      <li>
        <span><i class="bi bi-geo-alt"></i></span>
        <div class="info">
           <h6>{{ $t('adress') }}</h6> 
           <p>{{ information.address }}</p>
        </div>
      </li>
     </ul>
  </div>
</template>

<script>
import {contactData} from "@/api/contact/information.js"

export default {
  data() {
    return {
      information: {}
    };
  },
  mounted() {
    contactData().then((response) => {
      this.information = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
}
</script>

<style>

</style>