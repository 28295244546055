<template>
  <div class="layout">
    <!-- ommited -->
    <SocialChat icon :attendants="attendants">
      <template #header>
        <p>{{$t("Click on one of our attendants below to chat on WhatsApp.")}}</p>
      </template>
      <template #button>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        />
      </template>
      <template #footer>
        <small>{{$t("Open 24 hours")}}</small>
      </template>
    </SocialChat>
  </div>
</template>

<script>
import { SocialChat } from "vue-social-chat";

export default {
  components: {
    SocialChat,
  },
  setup() {
    const attendants = [
      {
        app: "whatsapp",
        label: "Technical support",
        name: "Sandak",
        number: "+966561492362",
        avatar: {
          src: "https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4",
          alt: "Alan Ktquez avatar",
        },
      },
      // ...
    ];

    return { attendants };
  },
};
</script>

<style></style>
