<template>
    <section class="legality_detials">
        <breadcrumb-lawyer :info="info" />
 
        <div class="container">
            <div class="content"></div>
    <div class="row">
        <div class="col-lg-3 col-md-4 col-12">
         <Sidebar />
        </div>
        <div class="col-lg-9 col-md-8 col-12">
          <Reservation />
         
        </div>
    </div>
  </div>
    </section>
 
</template>

<script>
import breadcrumbLawyer from "@/components/layout/breadcrumbLawyer.vue";
import Reservation from "@/components/legalitySection/reservation.vue";
import Sidebar from "@/components/legalitySection/sidebar.vue";

export default {
  components: {
    breadcrumbLawyer,
    Sidebar,
    Reservation,
},
  data() {
    return {
      info: {
        title:this.$t('addvice_order'),
      },
    };
  },
};
</script>

<style></style>
