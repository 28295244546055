<template>
  <div class="ask_circle" @click="showMenu()">
    <!-- <div class="wave"></div> -->
    <div class="content">
        <h6>{{ $t("ask_help") }} <i class="bi bi-question-lg"></i></h6>
        
    </div>
    
    <ul class="list" :class="{ 'show': isMenuVisible }" >
      <li>
        <router-link class="link" :to="{ name: 'legalityOrder' }">
          {{ $t("addvice_order") }}</router-link
        >
      </li>
      <li>
        <router-link class="link" :to="{ name: 'referOrder' }">
          {{ $t("refer_order") }}</router-link
        >
      </li>
      <li>
        <router-link class="link" :to="{ name: 'caseOrder' }">
          {{ $t("case_order") }}</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
    data() {
    return {
      isMenuVisible: false
    };
  },
    methods:{
        showMenu(){
            this.isMenuVisible = !this.isMenuVisible;
        }
    }
};
</script>

<style></style>
