<template>
  <section class="blogs_page">
    <Header />
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-7 col-12">
          <div class="card_blogs">
            <div class="row">
              <div
                class="col-lg-6 col-12"
                v-for="(card, index) in cards.data"
                :key="index"
              >
                <router-link class="card" :to="`/blogs/${card.id}`">
                    <img :src="card.image" alt="blog" />
                    <span>{{ card.date }}</span>
                    <h4>{{ card.title }}</h4>
                    <p v-html="card.text"></p>
                    <router-link :to="`/blogs/${card.id}`" class="btn second">{{
                      $t("read_more")
                    }}</router-link>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <pagination
              :data="cards"
              class="mx-auto d-flex align-items-center justify-content-center pagination"
              @pagination-change-page="fetch_blog_data"
            >
              <span slot="prev-nav">&lt;</span>
              <span slot="next-nav">&gt;</span>
            </pagination>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 col-12">
          <last-news />
          <Categories />
          <Tages />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";

import Header from "@/components/blogs/header.vue";
import Cards from "@/components/blogs/cards.vue";
import lastNews from "@/components/blogs/lastNews.vue";
import Categories from "@/components/blogs/categories.vue";
import Tages from "@/components/blogs/tags.vue";
import pagination from "laravel-vue-pagination";
// import { blogsData } from "@/api/blogs/cards.js";

export default {
  components: {
    pagination,
    Header,
    Cards,
    lastNews,
    Categories,
    Tages,
  },
  data() {
    return {
      cards: {},
    };
  },
  methods: {
    fetch_blog_data(page = 1) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("fetch_blogs?page=" + page).then(({ data }) => {
        this.cards = data.data;
        // console.log(this.cards);
      });
    },
  },
  created() {
    this.fetch_blog_data();
  },
  // mounted() {
  //   blogsData().then((response) => {
  //     this.cards = response.data.data;
  //     // console.log(this.header);
  //     return response;
  //   });
  // },
};
</script>

<style></style>
