<template>
  <section class="login_page">
    <div class="container">
      <div class="text">
        <h2>{{ $t("ver_code") }}</h2>
        <p>{{ $t("enter_email_code") }}</p>
      </div>
      <form @submit.prevent="resetCode()">
        <div class="input-group">
          <label class="form-label"> {{ $t("email") }}</label>
          <input
            type="number"
            class="form-control"
            :placeholder="$t('full_email')"
            v-model="change.email"
            required
          />
          <span class="icon_form">
            <i class="bi bi-envelope"></i>
          </span>
        </div>
        <div class="input-group">
          <label class="form-label"> {{ $t("ver_code") }}</label>
          <input
            type="number"
            class="form-control"
            v-model="change.code"
            required
          />
          <!-- <span class="icon_form">
            <i class="bi bi-envelope"></i>
          </span> -->
        </div>
        <button type="submit" class="btn first">{{ $t("send") }}</button>
      </form>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "login",
  data() {
    return {
      change: {
        email: "",
        code: "",
      },
    };
  },
  methods: {
    async resetCode() {
      const formData = new FormData();
      formData.append("email", this.change.email);
      formData.append("email", this.change.code);
      axios
        .post("/check_code", formData)
        .then((response) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "success",
          });
          this.$router.push("/send-code");
        })
        .catch((error) => {
          Swal.fire({
            title: "",
            text: response.data.message,
            icon: "error",
          });
        });
    },
  },
};
</script>

<style></style>
