<template>
  <ul class="drop">
    <li>
      <router-link class="list" to="/guide/1">
        {{ $t("show_detials") }}
      </router-link>
    </li>
    <li>
      <router-link class="list" to="#"> {{ $t("addvice_order") }} </router-link>
    </li>
    <li>
      <button @click="confirmDelete()" class="list btn">
        {{ $t("hidden") }}
      </button>
    </li>
  </ul>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "drop menu",
  methods: {
    confirmDelete() {
      Swal.fire({
        title: this.$t("are_you_hidden"),
        icon: "warning",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("hidden"),
        cancelButtonText: this.$t("not_need"),
      }).then((result) => {
        if (result.value) {
          // const data = { message: 'Data passed!' };
       

        }
      });
    },
   
  },
};
</script>

<style></style>
