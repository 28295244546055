<template>
  <div class="image_liberary" id="scrollspyHeading3">
    <h2>{{ $t("img_liberary") }}</h2>
 
    <div class="img">
      <div class="image-grid">
        <img
          v-for="(image, index) in data.lawyer_libraries"
          :key="index"
        
          :src="image.image"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
 
 data(){
  return{
    data:{}
  }
 },
 methods: {
    fetch_image_data() {
      let id = { lawyer_id: this.$route.params.id };
      // console.log(id);
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.post("/lawyer_details", id).then(({ data }) => {
        this.data = data.data;
        // console.log(this.cards);
      });
    },
  },
  created() {
    this.fetch_image_data();
  }
 
};
</script>

<style></style>
