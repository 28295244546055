<template>
  <section class="header_index">
    <div class="container-fluid px-0">
   <div class="img">
    <img :src="header.image" alt="hero">
    <div class="overlay"></div>
   </div>
      <div class="text">
      
        <h1>{{header.title}}</h1>
        <p v-html="header.text"></p>
      </div>
    </div>
  </section>
</template>

<script>
import {headerData} from "@/api/home/header.js"

export default {
  data() {
    return {
      header: {}
    };
  },
  mounted() {
    headerData().then((response) => {
      this.header = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
