<template>
    <div class="breadcrumb_section">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-6 col-12">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link :to="{ name: 'Home' }">
                    {{ $t("home_page") }}</router-link
                  >
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ info.title }}
                </li>
              </ol>
            </nav>
  
            <div class="text">
              <h2>{{ details.title }}</h2>
              <p>{{ $t("lawyer_desc") }}</p>
            </div>
          </div>
          <div class="col-lg-5 col-md-6 col-12">
            <div class="img">
              <img src="../../assets/image/breadcremp.png" alt="breadcrumb" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { legalData } from "@/api/legality/details.js";

  export default {
    name: "braedcrumb",
    props: ["info"],
    data(){
        return{
            details:{ }
        }
    },
    mounted() {
    legalData().then((response) => {
      this.details = response.data.data;
        //   console.log(this.details);
      return response;
    });
  },
  };
  </script>
  
  <style></style>
  