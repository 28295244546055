<template>
  <div class="faq_section">
    <div class="text">
      <h2>{{ $t('faq_title') }}</h2>
      <!-- <p>{{ faq.text }}</p> -->
    </div>

    <div class="accordion" id="accordionExample">
      <div
        class="accordion-item"
        v-for="(question, index) in questions"
        :key="index"
      >
        <h2 class="accordion-header" :id="`heading${index}`">
          <button
            :class="`accordion-button ${index == 0 ? '' : 'collapsed'}`"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${index}`"
            aria-expanded="true"
            :aria-controls="`collapse${index}`"
          >
            {{ question.question }}
          </button>
        </h2>
        <div
          :id="`collapse${index}`"
          :class="`accordion-collapse collapse ${index == 0 ? 'show' : ''}`"
          :aria-labelledby="`heading${index}`"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body">
            {{ question.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { joinData } from "@/api/join/faq.js";

export default {
  data() {
    return {
     
        questions: [],
      
    };
  },
  mounted() {
    joinData().then((response) => {
      this.questions = response.data.data;
      // console.log(this.header);
      return response;
    });
  },
};
</script>

<style></style>
