<template>
  <section class="lawyer_section">
    <div class="container">
      <lawyer-header-secend />
      <div class="head_grid">
        <h2>
          {{ $t("all_lawyer") }}
        </h2>
        <div class="grid_list_menu">
          <span :class="{ active: grid === true }" @click="showGrid()"
            ><i class="bi bi-grid"></i
          ></span>
          <span :class="{ active: list === true }" @click="showList()"
            ><i class="bi bi-list"></i
          ></span>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-5 col-12">
          <div class="filter_lawyer">
            <div class="button">
              <button @click="removeDataInput()" class="btn clear_data">
                {{ $t("clear_all") }}
                <i class="bi bi-funnel"></i>
              </button>
            </div>

            <div class="input-group">
              <!-- country -->
              <select
                class="form-select"
                aria-label="Default select example"
                @change="
                  fetch_lawyers_data();
                  fetchState($event);
                "
                v-model="country_id"
                @click="removeData()"
              >
                <option disabled selected>{{ $t("country_name") }}</option>
                <option
                  v-for="(country, index) in countries"
                  :key="index"
                  :value="country.id"
                >
                  {{ country.title }}
                </option>
              </select>
              <span v-if="!country_id" class="text">{{
                $t("country_name")
              }}</span>
              <!-- country -->
            </div>
            <div class="input-group">
              <!-- state -->
              <select
                class="form-select"
                aria-label="Default select example"
                @change="
                  fetch_lawyers_data();
                  fetchCity($event);
                "
                v-model="state_id"
                @click="removeData()"
              >
                <option selected>{{ $t("cities_name") }}</option>
                <option
                  v-for="(state, index) in states"
                  :key="index"
                  :value="state.id"
                >
                  {{ state.title }}
                </option>
              </select>
              <span v-if="!state_id" class="text">{{ $t("cities_name") }}</span>
              <!-- state -->
            </div>
            <div class="input-group">
              <!-- city -->
              <select
                class="form-select"
                v-model="city_id"
                aria-label="Default select example"
                @change="fetch_lawyers_data()"
                @click="removeData()"
              >
                <option disabled selected>{{ $t("department_name") }}</option>
                <option
                  v-for="(city, index) in cities"
                  :key="index"
                  :value="city.id"
                >
                  {{ city.title }}
                </option>
              </select>
              <span v-if="!city_id" class="text">{{
                $t("department_name")
              }}</span>
              <!-- city -->
            </div>
            <div class="input-group">
              <h4>{{ $t("cat") }}</h4>
              <div
                class="form-check"
                v-for="(cats, index) in category"
                :key="index"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="cats.id"
                  :id="`flexCheckChecked${index}`"
                  @change="fetch_lawyers_data()"
                  @click="removeData()"
                  v-model="law_category_ids"
                />
                <label
                  class="form-check-label"
                  :for="`flexCheckChecked${index}`"
                >
                  {{ cats.title }}
                </label>
              </div>
            </div>

            <div class="input-group">
              <h4>{{ $t("exp_yaers") }}</h4>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="zero"
                  value="0"
                  v-model="experience_years"
                  @change="fetch_lawyers_data()"
                  @click="removeData()"
                />
                <label class="form-check-label" for="zero">{{
                  $t("zero")
                }}</label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="one"
                  value="1"
                  v-model="experience_years"
                  @change="fetch_lawyers_data()"
                  @click="removeData()"
                />
                <label class="form-check-label" for="one">{{
                  $t("one")
                }}</label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="two"
                  value="2"
                  v-model="experience_years"
                  @change="fetch_lawyers_data()"
                  @click="removeData()"
                />
                <label class="form-check-label" for="two">{{
                  $t("two")
                }}</label>
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="radio"
                  id="three"
                  value="3"
                  v-model="experience_years"
                  @change="fetch_lawyers_data()"
                  @click="removeData()"
                />
                <label class="form-check-label" for="three">{{
                  $t("three")
                }}</label>
              </div>
            </div>

            <div class="avdts">
              <img src="../assets/image/asds.jpg" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-7 col-12" v-if="lawyercards.data.length">
          <div class="row" v-show="list">
            <lawyerCards
              v-for="(card, index) in lawyercards.data"
              :key="index"
              :cardSS="card"
            >
            </lawyerCards>
            <div class="row">
              <pagination
                :data="lawyercards"
                class="mx-auto d-flex align-items-center justify-content-center pagination"
                @pagination-change-page="fetch_lawyers_data"
              >
                <span slot="prev-nav">&lt;</span>
                <span slot="next-nav">&gt;</span>
              </pagination>
            </div>
            <h1 v-show="!this.lawyercards.length === 0" > {{noDataMessage}} </h1>
          </div>
          <div class="row" v-show="grid">
            <div
              class="col-lg-6 col-12"
              v-for="(card, index) in lawyercards.data"
              :key="index"
            >
              <lawyer-cards-grid :card="card" />
            </div>
            <div class="row">
              <pagination
                :data="lawyercards"
                class="mx-auto d-flex align-items-center justify-content-center pagination"
                @pagination-change-page="fetch_lawyers_data"
              >
                <span slot="prev-nav">&lt;</span>
                <span slot="next-nav">&gt;</span>
              </pagination>
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-7 col-12" v-else-if="!lawyercards.data.length">
          <h1>
           {{ $t('no_law') }}
          </h1>
         
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
// import { lawyerData } from "@/api/lawyer/cards.js";
import lawyerHeaderSecend from "@/components/lawyer/lawyerHeaderSecend.vue";
import lawyerFilter from "@/components/lawyer/lawyerFilter.vue";
import lawyerCards from "@/components/lawyer/lawyerCards.vue";
import lawyerCardsGrid from "@/components/lawyer/lawyerCardsGrid.vue";

export default {
  components: {
    lawyerHeaderSecend,
    lawyerFilter,
    lawyerCards,
    lawyerCardsGrid,
    pagination,
  },
  data() {
    return {
      lawyercards: {
        data: []
      },
      grid: false,
      list: true,
      countries: [],
      cities: [],
      states: [],
      category: [],
      country_id: "",
      city_id: "",
      state_id: "",
      law_category_ids: [],
      experience_years: "",
      noDataMessage:''
    };
  },

  mounted() {
    // Fetch categories data from localStorage on component mount
    let myData = JSON.parse(localStorage.getItem('myData'));
    if (myData) {
      this.country_id = JSON.parse(localStorage.getItem('myData')).country_id;
      this.state_id = JSON.parse(localStorage.getItem('myData')).state_id;
    }
    // console.log(this.state_id)
  },

  methods: {
    showGrid() {
      this.list = false;
      this.grid = true;
    },
    showList() {
      this.grid = !this.grid;
      this.list = true;
    },
    //pajination
    fetch_lawyers_data(page = 1) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      const SaveData = JSON.parse(localStorage.getItem("myData"));
      if (SaveData) {
        this.country_id = SaveData.country_id;
        this.city_id = SaveData.city_id;
        this.state_id = SaveData.state_id;
        this.law_category_ids = SaveData.law_category_ids;
        this.experience_years = SaveData.experience_years;
      }
      const data = {};
      if (this.country_id) {
        data["country_id"] = this.country_id;
      }
      if (this.city_id) {
        data["city_id"] = this.city_id;
      }
      if (this.state_id) {
        data["state_id"] = this.state_id;
      }
      if (this.law_category_ids) {
        data["law_category_ids"] = this.law_category_ids;
      }
      if (this.experience_years) {
        data["experience_years"] = this.experience_years;
      }
      const filter = SaveData != null ? SaveData : data;
      axios.post(`filter_lawyer?page=${page}`, filter).then(({ data }) => {
        this.lawyercards = data.data;
    // if (this.lawyercards.length === 0) {
    //   // Show "No data available" message
    //   console.log("No data available");
    //   // Or you can assign a message to a variable and display it in the template
    //    this.noDataMessage = "No data available";
    // }
        // console.log(this.lawyercards);
      });
    },

    //fetch country
    fetch_country_data() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_countries").then(({ data }) => {
        this.countries = data.data;
        // console.log(this.heroData);
      });
    },
    //fetch state
    fetchState(e) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;

      let country = { country_id: e.target.value };
      axios.post("/fetch_states_by_country_id", country).then((data) => {
        // console.log(data.data);
        if (data.status == 200) {
          this.states = data.data.data;
        }
      });
    },

    fetchLocalState() {
      let myData = JSON.parse(localStorage.getItem('myData'));
      if (myData) {
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;

        let country = {country_id: this.country_id };
        // console.log(country);
        axios.post("/fetch_states_by_country_id", country).then((data) => {
          // console.log(data.data);
          if (data.status == 200) {
            this.states = data.data.data;
          }
        });
      }
    },

    //fetch department
    fetchCity(x) {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      let state = { state_id: x.target.value };
      axios.post("/fetch_cities_by_state_id", state).then((data) => {
        // console.log(data.data);
        if (data.status == 200) {
          this.cities = data.data.data;
          // console.log(this.department);
        }
      });
    },

    fetchLocalCity() {
      let myData = JSON.parse(localStorage.getItem('myData'));
      if (myData) {
        const newLocal = this.$i18n.locale;
        axios.defaults.headers.common["Accept-Language"] = newLocal;

        let state = {state_id: this.state_id };
        // console.log(state);
        axios.post("/fetch_cities_by_state_id", state).then((data) => {
          // console.log(data.data);
          if (data.status == 200) {
            this.cities = data.data.data;
          }
        });
      }
    },

    fetch_category() {
      const newLocal = this.$i18n.locale;
      axios.defaults.headers.common["Accept-Language"] = newLocal;
      axios.get("/fetch_law_categories").then(({ data }) => {
        this.category = data.data;
        //
        // console.log(this.category);
      });
    },

    //remove data storage
    removeDataInput() {
      localStorage.removeItem("myData");
      this.country_id = null;
      this.city_id = null;
      this.state_id = null;
      this.law_category_ids = [];
      this.experience_years = "";
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },
    removeData() {
      let myData = JSON.parse(localStorage.getItem('myData'));
      if (myData) {
        this.country_id= "",
        this.city_id= "",
        this.state_id= "",
        this.law_category_ids = [];
        this.experience_years = "";
        localStorage.removeItem("myData");
      }
    },
  },
  created() {
    this.fetch_lawyers_data();
    this.fetch_country_data();
    this.fetch_category();
    this.fetchLocalState();
    this.fetchLocalCity();
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem("myData");
    next();
    // console.log("test");
  },
};
</script>

<style></style>
