<template>
  <div class="ready_now">
    <h2>{{  $t('are_ready') }}</h2>
    <div class="button">
      <router-link :to="{ name: 'Contract' }" class="first btn">{{
              $t("annouc_now")
            }}</router-link>
          </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>