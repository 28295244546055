import { createRouter, createWebHistory } from "vue-router";
import store from '../store/index.js';

//import website
import Home from "../view/home";
import Giude from "../view/guide";
import lawyerDetials from "../view/lawyerDetials";
import About from "../view/about";
import Service from "../view/service";
import Blogs from "../view/blogs";
import Tags from "../view/tags";
import Category from "../view/category";
import Condition from "../view/Condition";
import Error from "../view/Error";


import singlePage from "../view/blog";
import Contact from "../view/contact";
import Join from "../view/join";
import Adv from "../view/adv";
import Contract from "../view/contract";
import Contracts from "../view/contracts";

// legality
import legalityOrder from "../view/legality/order.vue";
import legalityLawyer from "../view/legality/lawyer.vue";
import legalityReserve from "../view/legality/reserve.vue";
//refer
import referOrder from "../view/refer/order.vue";
import referLawyer from "../view/refer/lawyer.vue";
import referReserve from "../view/refer/reserve.vue";
//case
import caseOrder from "../view/case/order.vue";
import caseLawyer from "../view/case/lawyer.vue";
import caseReserve from "../view/case/reserve.vue";

//dashboard
import login from "../view/dashboard/login.vue";
import forgetPassword from "../view/dashboard/forgetPassword.vue";
import sendCode from "../view/dashboard/sendCode.vue";
import resetPassword from "../view/dashboard/resetPassword.vue";

import homeDashboard from "../view/dashboard/homeDashboard.vue";
import moneyDashboard from "../view/dashboard/moneyDashboard.vue";
import acceptConsultant from "../view/dashboard/acceptOffer.vue";






const routes = [
  // Website routes
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
    }
  },
  {
    path: "/guide",
    name: "Giude",
    component: Giude,
    meta: {
      title: "Giude",
    }
  },
  {
    path: "/guide/:id",
    name: "lawyerDetials",
    component: lawyerDetials,
    meta: {
      title: "lawyerDetials",
    }
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      title: "About",
    }
  },
  {
    path: "/service",
    name: "Service",
    component: Service,
    meta: {
      title: "Service",
    }
  },
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
    meta: {
      title: "Blogs",
    }
  },
  {
    path: "/tags/:id",
    name: "Tags",
    component: Tags,
    meta: {
      title: "Tags",
    }
  },

  {
    path: "/category/:id",
    name: "Category",
    component: Category,
    meta: {
      title: "Category",
    }
  },
  {
    path: "/blogs/:id",
    name: "singlePage",
    component: singlePage,
    meta: {
      title: "singlePage",
    }
  },
  {
    path: "/condition",
    name: "Condition",
    component: Condition,
    meta: {
      title: "Condition",
    }
  },

  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Contact",
    }
  },
  {
    path: "/join",
    name: "Join",
    component: Join,
    meta: {
      title: "Join",
    }
  },
  {
    path: "/adv",
    name: "Adv",
    component: Adv,
    meta: {
      title: "Adv",
    }
  },
  {
    path: "/contract",
    name: "Contract",
    component: Contract,
    meta: {
      title: "Contract",
    }
  },

  {
    path: "/contracts",
    name: "Contracts",
    component: Contracts,
    meta: {
      title: "Contracts",
    }
  },
  // Website routes
  //legality routes
  {
    path: "/legality/order",
    name: "legalityOrder",
    component: legalityOrder,
    meta: {
      title: "legalityOrder",
    }
  },
  {
    path: "/legality/order/:id",
    name: "legalityLawyer",
    component: legalityLawyer,
    meta: {
      title: "legalityLawyer",
    }
  },
  {
    path: "/legality/reserve/:id",
    name: "legalityReserve",
    component: legalityReserve,
    meta: {
      title: "legalityReserve",
    }
  },
  //legality routes

  //refer routes
  {
    path: "/refer/order",
    name: "referOrder",
    component: referOrder,
    meta: {
      title: "referOrder",
    }
  },
  {
    path: "/refer/order/:id",
    name: "referLawyer",
    component: referLawyer,
    meta: {
      title: "referLawyer",
    }
  },
  {
    path: "/refer/reserve/:id",
    name: "referReserve",
    component: referReserve,
    meta: {
      title: "referReserve",
    }
  },
  //refer routes

  //case routes
  {
    path: "/case/order",
    name: "caseOrder",
    component: caseOrder,
    meta: {
      title: "caseOrder",
    }
  },
  {
    path: "/case/order/:id",
    name: "caseLawyer",
    component: caseLawyer,
    meta: {
      title: "caseLawyer",
    }
  },
  {
    path: "/case/reserve/:id",
    name: "caseReserve",
    component: caseReserve,
    meta: {
      title: "caseReserve",
    }
  },
  //refer routes
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      title: "login",
      guest: true
    }
  },
  {
    path: "/forget-password",
    name: "forgetPassword",
    component: forgetPassword,
    meta: {
      title: "forgetPassword",
      guest: true
    }
  },
  {
    path: "/send-code",
    name: "sendCode",
    component: sendCode,
    meta: {
      title: "sendCode",
      guest: true
    }
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    component: resetPassword,
    meta: {
      title: "resetPassword",
      guest: true
    }
  },
  
  //dashboard
  {
    path: "/dashboard",
    name: "homeDashboard",
    component: homeDashboard,
    meta: {
      title: "homeDashboard",
      requiresAuth: true
    }
  },
  {
    path: "/money-dashboard",
    name: "moneyDashboard",
    component: moneyDashboard,
    meta: {
      title: "moneyDashboard",
      requiresAuth: true
    }
  },
  {
    path: "/accept-consult/:id",
    name: "acceptConsultant",
    component: acceptConsultant,
    meta: {
      title: "acceptConsultant",
      requiresAuth: true
    }
  },
  //dashboard

  { path: "/404", component: Error },
  { path: "/:catchAll(.*)", redirect: "/404" },


];

const router = createRouter({
  routes,
  history: createWebHistory(),
  hashbang: false,
  mode: 'html5',
  linkActiveClass: "active",
  // linkExactActiveClass: "exact-active",
  scrollBehavior() {
    // Scroll to the top of the page
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/dashboard");
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
