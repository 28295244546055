<template>
  <section class="contact_section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="text">
            <h2>{{ $t("contact_office") }}</h2>
            <p class="p-0">{{ $t("contact_text") }}</p>
          </div>
          <formContact />
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <information />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import information from "@/components/contact/information.vue";
import  formContact  from "@/components/contact/form.vue";

export default {
  components: {
    formContact,
    information,
  },
};
</script>

<style></style>
